import styled from "styled-components"
import { colors } from "../utils/colors";
import * as TypographyStyles from './TypographyStyles';

const Container = styled('div')`
  display: flex;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
  background: ${colors.white};
`

const Title = styled('div')`
  display: flex;
  padding: 0px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`

const List = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
`

const ListItem = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  align-self: stretch;
  padding: 12px 12px;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${colors.keyDarker};
  cursor: pointer;
`

interface RecentSearch {
  originId: string;
  destinationId: string;
  originName: string;
  destinationName: string;
  date: string;
  tripType: string;
  options: Record<string, string>;
  timestamp: string;
}

interface RecentsProps {
  recentSearches: RecentSearch[];
  onSearch: (search: RecentSearch) => void;
}

const formatDate = (dateString: string) => {
  const searchDate = new Date(dateString);
  const now = new Date();
  const isToday =
    searchDate.getDate() === now.getDate() &&
    searchDate.getMonth() === now.getMonth() &&
    searchDate.getFullYear() === now.getFullYear();
  const isTomorrow =
    searchDate.getDate() === now.getDate() + 1 &&
    searchDate.getMonth() === now.getMonth() &&
    searchDate.getFullYear() === now.getFullYear();

  if (isToday) {
    return `today at ${searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else if (isTomorrow) {
    return `tomorrow at ${searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  } else {
    const datePart = searchDate.toLocaleDateString([], {
      day: 'numeric',
      month: 'numeric'
    });
    const timePart = searchDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${datePart} at ${timePart}`;
  }
};

const getUniqueSearches = (searches: RecentSearch[]): RecentSearch[] => {
  const seen = new Set<string>();
  const uniqueSearches: RecentSearch[] = [];
  
  // Process searches in reverse chronological order (most recent first)
  for (let i = searches.length - 1; i >= 0; i--) {
    const search = searches[i];
    const key = `${search.originId}-${search.destinationId}`;
    if (!seen.has(key)) {
      seen.add(key);
      uniqueSearches.push(search);
      if (uniqueSearches.length === 5) break; // Stop once we have 5 unique searches
    }
  }
  
  return uniqueSearches; // Already in most-recent-first order
};

const Recents: React.FC<RecentsProps> = ({ recentSearches, onSearch }) => {
  const uniqueRecentSearches = getUniqueSearches(recentSearches);

  const handleListItemClick = (search: RecentSearch) => {
    console.log(search);
    onSearch(search);
  };

  return (
    <Container>
      <Title>
        <TypographyStyles.BodyXLSemibold color={colors.black}>Search again</TypographyStyles.BodyXLSemibold>
      </Title>
      <List>
        {uniqueRecentSearches.map((search, index) => (
          <ListItem key={index} onClick={() => handleListItemClick(search)}>
            <TypographyStyles.BodyLSemibold color={colors.keyDarker}>
              {search.originName} ➜ {search.destinationName}
            </TypographyStyles.BodyLSemibold>
            <TypographyStyles.BodyMRegular color={colors.grey600}>
              {search.tripType === 'departure' ? 'departing' : 'arriving'} {formatDate(search.date)}
            </TypographyStyles.BodyMRegular>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Recents;